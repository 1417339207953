import React from 'react'

export default class ContactUsForm extends React.Component {

    state = {
        name: "",
        email: "",
        company: "",
        message: ""
    }

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({
          [name]: value,
        })
    }

    handleSubmit = event =>  {

        event.preventDefault();
        const URL = "https://ur8ex2v348.execute-api.us-east-1.amazonaws.com/prod/contact";
        const data = {
            name: this.state.name,
            email: this.state.email,
            company: this.state.company,
            message: this.state.message
        };
        const req = new XMLHttpRequest();
        req.addEventListener( 'load', function( e ) {
            window.location.href = "/confirmation";
        });
        req.addEventListener( 'error', function( e ) {
            window.location.href = "/confirmation";
        });
        req.open('POST', URL);
        req.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        req.send(JSON.stringify(data));
    }

    render() {
        return (
            <form id="contact-form" method="post" name="contact" onSubmit={this.handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />

              <div className="contact_form--name">
                <input className="contact_form--input" name="name" placeholder="Name" type="text" required value={this.state.name} onChange={this.handleInputChange} />
              </div>
              <div className="contact_form--email">
                <input id="email-input" className="contact_form--input" name="email" placeholder="Email" type="email" required value={this.state.email} onChange={this.handleInputChange} />
              </div>
              <div className="contact_form--company">
                <input id="company-input" className="contact_form--input" name="company" placeholder="Company" type="text" value={this.state.company} onChange={this.handleInputChange}/>
              </div>
              <div className="contact_form--message">
                <textarea id="message-input" className="contant_form--textarea" name="message" placeholder="Message" required value={this.state.message} onChange={this.handleInputChange}></textarea>
              </div>
              <button type="submit" className="contact_form--submit">Submit</button>
            </form>
        )
    }
}
