import React from 'react';
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import gmaps from '../images/gmaps.png'
import ContactUsForm from '../components/ContactUsForm'


export default () => (
    <div id="app" className="contactus">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh Contact Page"></meta>
          <title>WRAL Digital Solutions | Contact Us</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/contact/" />
        </Helmet>

      <PreHeader/>
      <Header/>

      <div className="contact">

        <h1 className="contact--title">Contact WRAL Digital Solutions</h1>
        <div className="contact_form">
          <div className="contact_form-one">
            <div className="contact--address">
                <h5>WRAL Digital Solutions</h5>
                <a href="https://www.google.com/maps/search/?api=1&query=WRAL+Digital+Solutions">
                  <p>2619 Western Blvd,</p>
                  <p>Raleigh, NC, 27606</p>
                </a>
            </div>

            <div className="contact--email">
              <h5>Email Us</h5>
              <a href="mailto:info@wraldigitalsolutions.com">info@wraldigitalsolutions.com</a>
            </div>

            <div className="contact--call">
              <h5>Call Us</h5>
              <a href="tel:+1-919-744-3030">919-744-3030</a>
            </div>
          </div>

          <div className="contact_form-two">
            <h3 className="contact_form--title">Send Us a Message</h3>
            <ContactUsForm/>
          </div>
        </div>
        <a href="https://www.google.com/maps/search/?api=1&query=WRAL+Digital+Solutions"><img src={gmaps} id="gmaps" alt="a screenshot of a map of raleigh, with WRAL digital solutions as a marker" /></a>
        <h2 id="aboutus">About Us</h2>
        <p className="content">
          WRAL Digital Solutions is here to help. In the complex and ever-changing world of digital marketing, we are a trusted local partner,
          combining exclusive advertising opportunities on WRAL.com with a full complement of digital marketing services,
          driving transparent and data-driven results for our clients.
        </p>
        <div className="chris-blurb">
          <h3>Chris Weatherly</h3>
          <h5>General Manager</h5>
          <p>
            Chris is the General Manager for WRAL Digital Solutions and WRAL.com
            and is passionate about simplifying the complex world of digital advertising and marketing
            for local businesses. His main day-to-day responsibility is making sure the talented team at
            WRAL Digital Solutions continues to evolve and generate great results for our clients.
            Additionally, Chris oversees the programmatic and local sales strategy for WRAL.com,
            sits on the Local Media Foundation Board and has spoken at industry conferences on a
            variety of topics including OTT, DMPs, beacon targeting and WRAL Digital Solutions.
          </p>
        </div>

      </div>
      <div id="contact">
        <Footer/>
      </div>
    </div>
  );
